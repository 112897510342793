import React, {useState} from 'react';

import {useRouter} from 'next/router';

import {MenuItem} from '@mui/material';
import cn from 'classnames';
import Netherland from '@app/components/icons/flags/netherland';
import USA from '@app/components/icons/flags/usa';
import MenuDropdown from "@app/components/common/MenuDropdown/MenuDropdown";
import {Check} from "@app/components/icons/check";
import {useBreakpoint} from "@app/lib/hooks/use-breakpoint";

export default function LocaleDropdownUi() {
    const router = useRouter();
    const breakpoints = useBreakpoint();
    const {pathname, asPath, query} = router;
    const [locale, setLocale] = useState(router.locale ?? 'EN');
    const dropdownOptions = [
        {
            label: 'en',
            value: 'ENGLISH',
            icon: USA
        },
        {
            label: 'nl',
            value: 'NEDERLAND',
            icon: Netherland
        }
    ];

    const handleLocale = (label: string) => {
        router.push({pathname, query}, asPath, {locale: label.toLowerCase()});
        localStorage.setItem('language', label);
        setLocale(label);
    };
    return (
        <div className="flex items-center">
            <MenuDropdown
                PaperProps={{
                    elevation: 0,
                    sx: {
                        width: 200,
                        overflow: 'hidden',
                        borderRadius: 2,
                        filter: 'drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.15))',
                        mt: 0.5,
                        padding: 0
                    }
                }}
                id="language-menu"
                menuTitle={''}
                menuContent=
                    {
                        dropdownOptions.map(dd => {
                            if (dd.label == locale.toLowerCase()) {
                                return <React.Fragment key={dd.label}>
                                    {React.createElement(dd.icon, {className: 'h-5 w-6'})}
                                    {['xs', '2xs', 'sm', "md"].indexOf(breakpoints) === -1 &&
                                        <span data-testid={`current-locale-${dd.label}`}
                                              className="p3 text-black-600">{dd?.label.toUpperCase()}</span>}
                                </React.Fragment>;
                            }
                        })
                    }
            >
                {dropdownOptions.map((dd: any) => (
                    <MenuItem data-testid={dd.label} onClick={() => handleLocale(dd.label)}
                              className="py-4 justify-between hover:bg-black-200"
                              key={dd.value}>
                        <div
                            className={cn('flex gap-3 body3  items-center  ', locale === dd.label && '!text-brand-600 ')}>
                            {React.createElement(dd.icon, {className: 'h-5 w-6'})} {dd?.value}
                        </div>
                        {locale === dd.label &&
                            <Check className="h-5 w-5" color="#0C50B4"/>}
                    </MenuItem>
                ))}
            </MenuDropdown>
        </div>
    );
}
